import {gql} from "@apollo/client";
import {StorageFragment} from "./Storage";
import {StorageFragmentAdmin} from "./Storage";

export const ProductDocumentFragment = gql`
	${StorageFragment}
    fragment ProductDocumentFragment on ProductDocument {
		id
		title
		description
		document 	 { 
			...StorageFragment 
		}
    }
`

export const ProductDocumentFragmentAdmin = gql`
	${StorageFragmentAdmin}
    fragment ProductDocumentFragmentAdmin on ProductDocument {
		id
		title
		titles
		description
		descriptions
		document 	 { 
			...StorageFragmentAdmin 
		}
    }
`

export const UpdateProductDocument = gql`
	${ProductDocumentFragment}
     mutation ($id: ID!, $title: String, $description: String) {
         UpdateProductDocument (id: $id, title: $title, description: $description) {
			...ProductDocumentFragment
         }
    }
`

