import {lazy} from 'react';
import LoadCategoriesWrapper from "./categories/wrappers/LoadCategoriesWrapper.js";
import CampaignWrapper from "./groups/wrappers/CampaignWrapper.js";
import LangHooksWrapper from "./lang/wrappers/LangHooksWrapper.js";
import MatchLangWrapper from "./lang/wrappers/MatchLangWrapper.js";
import PreloadBasketWrapper from "./orders/wrappers/PreloadBasketWrapper.js";
import useGetMenu from "./pages/widgets/useGetMenu.js";
import LoadPagesWrapper from "./pages/wrappers/LoadPagesWrapper.js";
import useQueryParser from "./products/widgets/useQueryParser.js";
import ServiceWorkerWrapper from "./pwa/wrappers/ServiceWorkerWrapper.js";
import RouteSeoMiddleware from "./seo/middlewares/RouteSeoMiddleware.js";
import seoAll from "./seo/providers/seoAll.js";
import LoadSettingsWrapper from "./settings/wrappers/LoadSettingsWrapper.js";
import image from "./storage/widgets/image.js";
import link from "./storage/widgets/link.js";
import PreloadWishlistWrapper from "./wishlist/wrappers/PreloadWishlistWrapper.js";


export const widgets = {
    "pwa.ServiceWorkerWrapper": {
        "type": "wrapper",
        "dependency": "first",
        "element": ServiceWorkerWrapper,
        "name": "pwa.ServiceWorkerWrapper"
    },
    "banners.CmsBannerItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBannerItem' */ './banners/views/cmsBanners/CmsBannerItem.js')),
        "name": "banners.CmsBannerItem"
    },
    "banners.CmsBanners": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsBanners",
        "title": "baneri",
        "cmsMenu": {"position": "10", "parent": "pages.CmsPages"},
        "element": lazy(() => import(/* webpackChunkName: 'CmsBanners' */ './banners/views/cmsBanners/CmsBanners.js')),
        "name": "banners.CmsBanners"
    },
    "banners.CmsBannersRender": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBannersRender' */ './banners/views/cmsBanners/CmsBannersRender.js')),
        "name": "banners.CmsBannersRender"
    },
    "banners.CmsBannersStats": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsBannersStats",
        "title": "baneri statistika",
        "cmsMenu": {"position": "40", "parent": "pages.CmsPages"},
        "element": lazy(() => import(/* webpackChunkName: 'CmsBannersStats' */ './banners/views/cmsBannersStats/CmsBannersStats.js')),
        "name": "banners.CmsBannersStats"
    },
    "banners.Banner": {
        "element": lazy(() => import(/* webpackChunkName: 'Banner' */ './banners/widgets/Banner.js')),
        "name": "banners.Banner"
    },
    "banners.BannersCarousel": {
        "element": lazy(() => import(/* webpackChunkName: 'BannersCarousel' */ './banners/widgets/BannersCarousel.js')),
        "name": "banners.BannersCarousel"
    },
    "banners.BannersPopup": {
        "element": lazy(() => import(/* webpackChunkName: 'BannersPopup' */ './banners/widgets/BannersPopup.js')),
        "name": "banners.BannersPopup"
    },
    "banners.PageBanners": {
        "element": lazy(() => import(/* webpackChunkName: 'PageBanners' */ './banners/widgets/PageBanners.js')),
        "name": "banners.PageBanners"
    },
    "blog.Blog": {
        "layout": "public.PublicLayout",
        "path": "route.blog",
        "element": lazy(() => import(/* webpackChunkName: 'Blog' */ './blog/views/blog/Blog.js')),
        "name": "blog.Blog"
    },
    "blog.LatestBlogs": {
        "element": lazy(() => import(/* webpackChunkName: 'LatestBlogs' */ './blog/views/blog/LatestBlogs.js')),
        "name": "blog.LatestBlogs"
    },
    "blog.Blogs": {
        "layout": "public.PublicLayout",
        "path": "route.blogs",
        "element": lazy(() => import(/* webpackChunkName: 'Blogs' */ './blog/views/blogs/Blogs.js')),
        "name": "blog.Blogs"
    },
    "blog.CmsBlog": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsBlog",
        "element": lazy(() => import(/* webpackChunkName: 'CmsBlog' */ './blog/views/cmsBlog/CmsBlog.js')),
        "name": "blog.CmsBlog"
    },
    "blog.AddBlockForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddBlockForm' */ './blog/views/cmsBlogs/AddBlockForm.js')),
        "name": "blog.AddBlockForm"
    },
    "blog.CmsBlogItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBlogItem' */ './blog/views/cmsBlogs/CmsBlogItem.js')),
        "name": "blog.CmsBlogItem"
    },
    "blog.CmsBlogs": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsBlogs",
        "cmsMenu": {"position": "45"},
        "title": "blog",
        "element": lazy(() => import(/* webpackChunkName: 'CmsBlogs' */ './blog/views/cmsBlogs/CmsBlogs.js')),
        "name": "blog.CmsBlogs"
    },
    "blog.BlogCarousel": {
        "element": lazy(() => import(/* webpackChunkName: 'BlogCarousel' */ './blog/widgets/BlogCarousel.js')),
        "name": "blog.BlogCarousel"
    },
    "blog.BlogRender": {
        "element": lazy(() => import(/* webpackChunkName: 'BlogRender' */ './blog/widgets/BlogRender.js')),
        "name": "blog.BlogRender"
    },
    "brands.Brands": {
        "layout": "public.PublicLayout",
        "path": "route.brands",
        "element": lazy(() => import(/* webpackChunkName: 'Brands' */ './brands/views/brands/Brands.js')),
        "name": "brands.Brands"
    },
    "brands.AddBrandForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddBrandForm' */ './brands/views/cmsBrands/AddBrandForm.js')),
        "name": "brands.AddBrandForm"
    },
    "brands.CmsBrandItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBrandItem' */ './brands/views/cmsBrands/CmsBrandItem.js')),
        "name": "brands.CmsBrandItem"
    },
    "brands.CmsBrands": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsBrands",
        "cmsMenu": {"position": "20", "parent": "products.CmsProducts"},
        "title": "brendovi",
        "element": lazy(() => import(/* webpackChunkName: 'CmsBrands' */ './brands/views/cmsBrands/CmsBrands.js')),
        "name": "brands.CmsBrands"
    },
    "brands.CmsStats": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsBrandsStats",
        "cmsMenu": {"position": "40", "parent": "products.CmsProducts"},
        "title": "brendovi statistika",
        "element": lazy(() => import(/* webpackChunkName: 'CmsStats' */ './brands/views/cmsStats/CmsStats.js')),
        "name": "brands.CmsStats"
    },
    "brands.ShopBrand": {
        "layout": "public.PublicLayout",
        "path": "route.shopBrand",
        "optionalPaths": "route.shopBrandOptionalPaths",
        "element": lazy(() => import(/* webpackChunkName: 'ShopBrand' */ './brands/views/shopBrand/ShopBrand.js')),
        "name": "brands.ShopBrand"
    },
    "brands.BrandsCarousel": {
        "element": lazy(() => import(/* webpackChunkName: 'BrandsCarousel' */ './brands/widgets/BrandsCarousel.js')),
        "name": "brands.BrandsCarousel"
    },
    "brands.CmsBrandsDrawer": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBrandsDrawer' */ './brands/widgets/CmsBrandsDrawer.js')),
        "name": "brands.CmsBrandsDrawer"
    },
    "brands.ShopBrands": {
        "element": lazy(() => import(/* webpackChunkName: 'ShopBrands' */ './brands/widgets/ShopBrands.js')),
        "name": "brands.ShopBrands"
    },
    "categories.AddCategoryForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddCategoryForm' */ './categories/views/cmsCategories/AddCategoryForm.js')),
        "name": "categories.AddCategoryForm"
    },
    "categories.CmsCategories": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsCategories",
        "cmsMenu": {"position": "10", "parent": "products.CmsProducts"},
        "title": "kategorije",
        "element": lazy(() => import(/* webpackChunkName: 'CmsCategories' */ './categories/views/cmsCategories/CmsCategories.js')),
        "name": "categories.CmsCategories"
    },
    "categories.CmsCategoryItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsCategoryItem' */ './categories/views/cmsCategories/CmsCategoryItem.js')),
        "name": "categories.CmsCategoryItem"
    },
    "categories.CmsStats": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsCategoriesStats",
        "cmsMenu": {"position": "30", "parent": "products.CmsProducts"},
        "title": "kategorije statistika",
        "element": lazy(() => import(/* webpackChunkName: 'CmsStats' */ './categories/views/cmsStats/CmsStats.js')),
        "name": "categories.CmsStats"
    },
    "categories.CategoriesCarousel": {
        "element": lazy(() => import(/* webpackChunkName: 'CategoriesCarousel' */ './categories/widgets/CategoriesCarousel.js')),
        "name": "categories.CategoriesCarousel"
    },
    "categories.CategoriesDrawer": {
        "element": lazy(() => import(/* webpackChunkName: 'CategoriesDrawer' */ './categories/widgets/CategoriesDrawer.js')),
        "name": "categories.CategoriesDrawer"
    },
    "categories.CategoriesDrawer2": {
        "element": lazy(() => import(/* webpackChunkName: 'CategoriesDrawer2' */ './categories/widgets/CategoriesDrawer2.js')),
        "name": "categories.CategoriesDrawer2"
    },
    "categories.CmsCategoriesDrawer": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsCategoriesDrawer' */ './categories/widgets/CmsCategoriesDrawer.js')),
        "name": "categories.CmsCategoriesDrawer"
    },
    "categories.CmsProductCategories": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductCategories' */ './categories/widgets/CmsProductCategories.js')),
        "name": "categories.CmsProductCategories"
    },
    "categories.ShopCategoriesMenu": {
        "element": lazy(() => import(/* webpackChunkName: 'ShopCategoriesMenu' */ './categories/widgets/ShopCategoriesMenu.js')),
        "name": "categories.ShopCategoriesMenu"
    },
    "categories.LoadCategoriesWrapper": {
        "type": "wrapper",
        "element": LoadCategoriesWrapper,
        "name": "categories.LoadCategoriesWrapper"
    },
    "cms.Error404": {
        "layout": "cms.CmsLayout",
        "path": "route.catchAll",
        "element": lazy(() => import(/* webpackChunkName: 'Error404' */ './cms/views/Error404/Error404.js')),
        "name": "cms.Error404"
    },
    "cms.AddAdministratorForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddAdministratorForm' */ './cms/views/administrators/AddAdministratorForm.js')),
        "name": "cms.AddAdministratorForm"
    },
    "cms.AdministratorItem": {
        "element": lazy(() => import(/* webpackChunkName: 'AdministratorItem' */ './cms/views/administrators/AdministratorItem.js')),
        "name": "cms.AdministratorItem"
    },
    "cms.Administrators": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsAdministrators",
        "cmsMenu": {"position": "50"},
        "title": "administratori",
        "element": lazy(() => import(/* webpackChunkName: 'Administrators' */ './cms/views/administrators/Administrators.js')),
        "name": "cms.Administrators"
    },
    "cms.UpdateAdministratorForm": {
        "element": lazy(() => import(/* webpackChunkName: 'UpdateAdministratorForm' */ './cms/views/administrators/UpdateAdministratorForm.js')),
        "name": "cms.UpdateAdministratorForm"
    },
    "cms.Dashboard": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsDashboard",
        "element": lazy(() => import(/* webpackChunkName: 'Dashboard' */ './cms/views/dashboard/Dashboard.js')),
        "name": "cms.Dashboard"
    },
    "cms.Email": {
        "layout": "cms.AuthLayout",
        "path": "route.cmsEmail",
        "element": lazy(() => import(/* webpackChunkName: 'Email' */ './cms/views/email/Email.js')),
        "name": "cms.Email"
    },
    "cms.AuthLayout": {
        "path": "route.cmsAuthLayout",
        "element": lazy(() => import(/* webpackChunkName: 'AuthLayout' */ './cms/views/layout/AuthLayout.js')),
        "name": "cms.AuthLayout"
    },
    "cms.CmsLayout": {
        "path": "route.cmsLayout",
        "element": lazy(() => import(/* webpackChunkName: 'CmsLayout' */ './cms/views/layout/CmsLayout.js')),
        "name": "cms.CmsLayout"
    },
    "cms.CmsLeftMenu": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsLeftMenu' */ './cms/views/layout/CmsLeftMenu.js')),
        "name": "cms.CmsLeftMenu"
    },
    "cms.Login": {
        "layout": "cms.AuthLayout",
        "path": "route.cmsLogin",
        "element": lazy(() => import(/* webpackChunkName: 'Login' */ './cms/views/login/Login.js')),
        "name": "cms.Login"
    },
    "cms.Reset": {
        "layout": "cms.AuthLayout",
        "path": "route.cmsReset",
        "element": lazy(() => import(/* webpackChunkName: 'Reset' */ './cms/views/reset/Reset.js')),
        "name": "cms.Reset"
    },
    "cms.ResetForm": {
        "element": lazy(() => import(/* webpackChunkName: 'ResetForm' */ './cms/views/reset/ResetForm.js')),
        "name": "cms.ResetForm"
    },
    "cms.DarkModeSwitcher": {
        "element": lazy(() => import(/* webpackChunkName: 'DarkModeSwitcher' */ './cms/widgets/DarkModeSwitcher.js')),
        "name": "cms.DarkModeSwitcher"
    },
    "design.CmsBlockBrands": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBlockBrands' */ './design/views/cmsBlockRenders/CmsBlockBrands.js')),
        "name": "design.CmsBlockBrands"
    },
    "design.CmsBlockBrandsItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBlockBrandsItem' */ './design/views/cmsBlockRenders/CmsBlockBrandsItem.js')),
        "name": "design.CmsBlockBrandsItem"
    },
    "design.CmsBlockCards": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBlockCards' */ './design/views/cmsBlockRenders/CmsBlockCards.js')),
        "name": "design.CmsBlockCards"
    },
    "design.CmsBlockCardsItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBlockCardsItem' */ './design/views/cmsBlockRenders/CmsBlockCardsItem.js')),
        "name": "design.CmsBlockCardsItem"
    },
    "design.CmsBlockCategories": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBlockCategories' */ './design/views/cmsBlockRenders/CmsBlockCategories.js')),
        "name": "design.CmsBlockCategories"
    },
    "design.CmsBlockCategoriesItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBlockCategoriesItem' */ './design/views/cmsBlockRenders/CmsBlockCategoriesItem.js')),
        "name": "design.CmsBlockCategoriesItem"
    },
    "design.CmsBlockProducts": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBlockProducts' */ './design/views/cmsBlockRenders/CmsBlockProducts.js')),
        "name": "design.CmsBlockProducts"
    },
    "design.CmsBlockText": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBlockText' */ './design/views/cmsBlockRenders/CmsBlockText.js')),
        "name": "design.CmsBlockText"
    },
    "design.AddBlockForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddBlockForm' */ './design/views/cmsBlocks/AddBlockForm.js')),
        "name": "design.AddBlockForm"
    },
    "design.CmsBlockItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBlockItem' */ './design/views/cmsBlocks/CmsBlockItem.js')),
        "name": "design.CmsBlockItem"
    },
    "design.CmsBlocks": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsBlocks' */ './design/views/cmsBlocks/CmsBlocks.js')),
        "name": "design.CmsBlocks"
    },
    "design.BlocksRender": {
        "element": lazy(() => import(/* webpackChunkName: 'BlocksRender' */ './design/widgets/BlocksRender.js')),
        "name": "design.BlocksRender"
    },
    "design.BlockBanners": {
        "element": lazy(() => import(/* webpackChunkName: 'BlockBanners' */ './design/widgets/blocks/BlockBanners.js')),
        "name": "design.BlockBanners"
    },
    "design.BlockCardsCarousel": {
        "element": lazy(() => import(/* webpackChunkName: 'BlockCardsCarousel' */ './design/widgets/blocks/BlockCardsCarousel.js')),
        "name": "design.BlockCardsCarousel"
    },
    "design.BlockText": {
        "element": lazy(() => import(/* webpackChunkName: 'BlockText' */ './design/widgets/blocks/BlockText.js')),
        "name": "design.BlockText"
    },
    "design.IndexSlider": {
        "element": lazy(() => import(/* webpackChunkName: 'IndexSlider' */ './design/widgets/blocks/IndexSlider.js')),
        "name": "design.IndexSlider"
    },
    "filters.AddFilterForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddFilterForm' */ './filters/views/cmsFilters/AddFilterForm.js')),
        "name": "filters.AddFilterForm"
    },
    "filters.CmsFilterItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsFilterItem' */ './filters/views/cmsFilters/CmsFilterItem.js')),
        "name": "filters.CmsFilterItem"
    },
    "filters.CmsFilters": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsFilters",
        "cmsMenu": {"position": "15", "parent": "products.CmsProducts"},
        "title": "filteri",
        "element": lazy(() => import(/* webpackChunkName: 'CmsFilters' */ './filters/views/cmsFilters/CmsFilters.js')),
        "name": "filters.CmsFilters"
    },
    "filters.CheckoutFilters": {
        "element": lazy(() => import(/* webpackChunkName: 'CheckoutFilters' */ './filters/widgets/CheckoutFilters.js')),
        "name": "filters.CheckoutFilters"
    },
    "filters.CmsProductFiltersALL": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductFiltersALL' */ './filters/widgets/CmsProductFiltersALL.js')),
        "name": "filters.CmsProductFiltersALL"
    },
    "filters.OrderFilters": {
        "element": lazy(() => import(/* webpackChunkName: 'OrderFilters' */ './filters/widgets/OrderFilters.js')),
        "name": "filters.OrderFilters"
    },
    "filters.ShopFilters": {
        "element": lazy(() => import(/* webpackChunkName: 'ShopFilters' */ './filters/widgets/ShopFilters.js')),
        "name": "filters.ShopFilters"
    },
    "groups.AddCampaignForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddCampaignForm' */ './groups/views/cmsGroups/AddCampaignForm.js')),
        "name": "groups.AddCampaignForm"
    },
    "groups.AddGroupForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddGroupForm' */ './groups/views/cmsGroups/AddGroupForm.js')),
        "name": "groups.AddGroupForm"
    },
    "groups.CmsGroupItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsGroupItem' */ './groups/views/cmsGroups/CmsGroupItem.js')),
        "name": "groups.CmsGroupItem"
    },
    "groups.CmsGroups": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsGroups",
        "cmsMenu": {"position": "20"},
        "title": "grupe proizvoda",
        "element": lazy(() => import(/* webpackChunkName: 'CmsGroups' */ './groups/views/cmsGroups/CmsGroups.js')),
        "name": "groups.CmsGroups"
    },
    "groups.CmsProductsGroupFilters": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductsGroupFilters' */ './groups/widgets/CmsProductsGroupFilters.js')),
        "name": "groups.CmsProductsGroupFilters"
    },
    "groups.CampaignWrapper": {"type": "wrapper", "element": CampaignWrapper, "name": "groups.CampaignWrapper"},
    "lang.LangSelect": {
        "element": lazy(() => import(/* webpackChunkName: 'LangSelect' */ './lang/widgets/LangSelect.js')),
        "name": "lang.LangSelect"
    },
    "lang.Wrapper": {
        "element": lazy(() => import(/* webpackChunkName: 'Wrapper' */ './lang/widgets/Wrapper.js')),
        "name": "lang.Wrapper"
    },
    "newsletter.CmsEmails": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsNewsletter",
        "title": "newsletter",
        "cmsMenu": {"position": "46"},
        "element": lazy(() => import(/* webpackChunkName: 'CmsEmails' */ './newsletter/views/cmsEmails/CmsEmails.js')),
        "name": "newsletter.CmsEmails"
    },
    "newsletter.NewsletterForm1": {
        "element": lazy(() => import(/* webpackChunkName: 'NewsletterForm1' */ './newsletter/widgets/NewsletterForm1.js')),
        "name": "newsletter.NewsletterForm1"
    },
    "orders.Checkout": {
        "layout": "public.PublicLayout",
        "path": "route.checkout",
        "element": lazy(() => import(/* webpackChunkName: 'Checkout' */ './orders/views/checkout/Checkout.js')),
        "name": "orders.Checkout"
    },
    "orders.CheckoutDeliveryMethods": {
        "element": lazy(() => import(/* webpackChunkName: 'CheckoutDeliveryMethods' */ './orders/views/checkout/CheckoutDeliveryMethods.js')),
        "name": "orders.CheckoutDeliveryMethods"
    },
    "orders.CheckoutForm": {
        "element": lazy(() => import(/* webpackChunkName: 'CheckoutForm' */ './orders/views/checkout/CheckoutForm.js')),
        "name": "orders.CheckoutForm"
    },
    "orders.CheckoutPaymentMethods": {
        "element": lazy(() => import(/* webpackChunkName: 'CheckoutPaymentMethods' */ './orders/views/checkout/CheckoutPaymentMethods.js')),
        "name": "orders.CheckoutPaymentMethods"
    },
    "orders.CheckoutPromoCode": {
        "element": lazy(() => import(/* webpackChunkName: 'CheckoutPromoCode' */ './orders/views/checkout/CheckoutPromoCode.js')),
        "name": "orders.CheckoutPromoCode"
    },
    "orders.CheckoutTable": {
        "element": lazy(() => import(/* webpackChunkName: 'CheckoutTable' */ './orders/views/checkout/CheckoutTable.js')),
        "name": "orders.CheckoutTable"
    },
    "orders.CmsCalendar": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsOrderCalendar",
        "cmsMenu": {"position": "5", "parent": "orders.CmsOrders"},
        "title": "kalendar",
        "element": lazy(() => import(/* webpackChunkName: 'CmsCalendar' */ './orders/views/cmsCalendar/CmsCalendar.js')),
        "name": "orders.CmsCalendar"
    },
    "orders.AddOrderCountryDeliveryMethodForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddOrderCountryDeliveryMethodForm' */ './orders/views/cmsOrderCountries/AddOrderCountryDeliveryMethodForm.js')),
        "name": "orders.AddOrderCountryDeliveryMethodForm"
    },
    "orders.AddOrderCountryForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddOrderCountryForm' */ './orders/views/cmsOrderCountries/AddOrderCountryForm.js')),
        "name": "orders.AddOrderCountryForm"
    },
    "orders.CmsOrderCountries": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsOrderCountries",
        "cmsMenu": {"position": "20", "parent": "orders.CmsOrders"},
        "title": "dostava zemlje",
        "element": lazy(() => import(/* webpackChunkName: 'CmsOrderCountries' */ './orders/views/cmsOrderCountries/CmsOrderCountries.js')),
        "name": "orders.CmsOrderCountries"
    },
    "orders.CmsOrderCountryDeliveryMethodItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsOrderCountryDeliveryMethodItem' */ './orders/views/cmsOrderCountries/CmsOrderCountryDeliveryMethodItem.js')),
        "name": "orders.CmsOrderCountryDeliveryMethodItem"
    },
    "orders.CmsOrderCountryDeliveryMethods": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsOrderCountryDeliveryMethods' */ './orders/views/cmsOrderCountries/CmsOrderCountryDeliveryMethods.js')),
        "name": "orders.CmsOrderCountryDeliveryMethods"
    },
    "orders.CmsOrderCountryItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsOrderCountryItem' */ './orders/views/cmsOrderCountries/CmsOrderCountryItem.js')),
        "name": "orders.CmsOrderCountryItem"
    },
    "orders.CmsOrderCountryPaymentMethods": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsOrderCountryPaymentMethods' */ './orders/views/cmsOrderCountries/CmsOrderCountryPaymentMethods.js')),
        "name": "orders.CmsOrderCountryPaymentMethods"
    },
    "orders.CmsOrderPaymentMethods": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsOrderPaymentMethods' */ './orders/views/cmsOrderSettings/CmsOrderPaymentMethods.js')),
        "name": "orders.CmsOrderPaymentMethods"
    },
    "orders.CmsOrderSettings": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsOrderSettings",
        "cmsMenu": {"position": "10", "parent": "orders.CmsOrders"},
        "title": "podesavanja",
        "element": lazy(() => import(/* webpackChunkName: 'CmsOrderSettings' */ './orders/views/cmsOrderSettings/CmsOrderSettings.js')),
        "name": "orders.CmsOrderSettings"
    },
    "orders.ChangeStatus": {
        "element": lazy(() => import(/* webpackChunkName: 'ChangeStatus' */ './orders/views/cmsOrders/ChangeStatus.js')),
        "name": "orders.ChangeStatus"
    },
    "orders.CmsOrderItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsOrderItem' */ './orders/views/cmsOrders/CmsOrderItem.js')),
        "name": "orders.CmsOrderItem"
    },
    "orders.CmsOrders": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsOrders",
        "cmsMenu": {"position": "40", "append": "orders.CmsOrdersUnseen"},
        "title": "porudzbine",
        "element": lazy(() => import(/* webpackChunkName: 'CmsOrders' */ './orders/views/cmsOrders/CmsOrders.js')),
        "name": "orders.CmsOrders"
    },
    "orders.CmsOrdersUnseen": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsOrdersUnseen' */ './orders/views/cmsOrders/CmsOrdersUnseen.js')),
        "name": "orders.CmsOrdersUnseen"
    },
    "orders.OrderComments": {
        "element": lazy(() => import(/* webpackChunkName: 'OrderComments' */ './orders/views/cmsOrders/OrderComments.js')),
        "name": "orders.OrderComments"
    },
    "orders.AddPromoCodeForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddPromoCodeForm' */ './orders/views/cmsPromoCodes/AddPromoCodeForm.js')),
        "name": "orders.AddPromoCodeForm"
    },
    "orders.CmsPromoCodeItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsPromoCodeItem' */ './orders/views/cmsPromoCodes/CmsPromoCodeItem.js')),
        "name": "orders.CmsPromoCodeItem"
    },
    "orders.CmsPromoCodes": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsPromoCodes",
        "cmsMenu": {"position": "20", "parent": "orders.CmsOrders"},
        "title": "promo kodovi",
        "element": lazy(() => import(/* webpackChunkName: 'CmsPromoCodes' */ './orders/views/cmsPromoCodes/CmsPromoCodes.js')),
        "name": "orders.CmsPromoCodes"
    },
    "orders.OrderStatus": {
        "layout": "public.PublicLayout",
        "path": "route.orderStatus",
        "optionalPaths": "route.orderStatusOptionalPaths",
        "element": lazy(() => import(/* webpackChunkName: 'OrderStatus' */ './orders/views/orderStatus/OrderStatus.js')),
        "name": "orders.OrderStatus"
    },
    "orders.UserOrders": {
        "layout": "users.UserLayout",
        "path": "route.userOrders",
        "element": lazy(() => import(/* webpackChunkName: 'UserOrders' */ './orders/views/userOrders/UserOrders.js')),
        "name": "orders.UserOrders"
    },
    "orders.BasketButton": {
        "element": lazy(() => import(/* webpackChunkName: 'BasketButton' */ './orders/widgets/BasketButton.js')),
        "name": "orders.BasketButton"
    },
    "orders.BasketDrawer": {
        "element": lazy(() => import(/* webpackChunkName: 'BasketDrawer' */ './orders/widgets/BasketDrawer.js')),
        "name": "orders.BasketDrawer"
    },
    "orders.CmsOrderDrawer": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsOrderDrawer' */ './orders/widgets/CmsOrderDrawer.js')),
        "name": "orders.CmsOrderDrawer"
    },
    "orders.CmsOrdersDrawer": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsOrdersDrawer' */ './orders/widgets/CmsOrdersDrawer.js')),
        "name": "orders.CmsOrdersDrawer"
    },
    "orders.OrderRender": {
        "element": lazy(() => import(/* webpackChunkName: 'OrderRender' */ './orders/widgets/OrderRender.js')),
        "name": "orders.OrderRender"
    },
    "orders.ProductForm": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductForm' */ './orders/widgets/ProductForm.js')),
        "name": "orders.ProductForm"
    },
    "orders.PreloadBasketWrapper": {
        "type": "wrapper",
        "element": PreloadBasketWrapper,
        "name": "orders.PreloadBasketWrapper"
    },
    "pages.AddPageForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddPageForm' */ './pages/views/cmsPages/AddPageForm.js')),
        "name": "pages.AddPageForm"
    },
    "pages.CmsPages": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsPages",
        "cmsMenu": {"position": "30"},
        "title": "stranice",
        "element": lazy(() => import(/* webpackChunkName: 'CmsPages' */ './pages/views/cmsPages/CmsPages.js')),
        "name": "pages.CmsPages"
    },
    "pages.PageSettings": {
        "element": lazy(() => import(/* webpackChunkName: 'PageSettings' */ './pages/views/cmsPages/PageSettings.js')),
        "name": "pages.PageSettings"
    },
    "pages.PageTemplate": {
        "element": lazy(() => import(/* webpackChunkName: 'PageTemplate' */ './pages/views/templates/PageTemplate.js')),
        "name": "pages.PageTemplate"
    },
    "pages.ShopTemplate": {
        "element": lazy(() => import(/* webpackChunkName: 'ShopTemplate' */ './pages/views/templates/ShopTemplate.js')),
        "name": "pages.ShopTemplate"
    },
    "pages.useGetMenu": {"element": useGetMenu, "name": "pages.useGetMenu"},
    "pages.LoadPagesWrapper": {"type": "wrapper", "element": LoadPagesWrapper, "name": "pages.LoadPagesWrapper"},
    "lang.MatchLangWrapper": {
        "type": "wrapper",
        "dependency": "pages.LoadPagesWrapper",
        "element": MatchLangWrapper,
        "name": "lang.MatchLangWrapper"
    },
    "lang.LangHooksWrapper": {
        "type": "wrapper",
        "dependency": "lang.MatchLangWrapper",
        "element": LangHooksWrapper,
        "name": "lang.LangHooksWrapper"
    },
    "products.Timer": {
        "element": lazy(() => import(/* webpackChunkName: 'Timer' */ './products/elements/Timer.js')),
        "name": "products.Timer"
    },
    "products.CmsProduct": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsProduct",
        "element": lazy(() => import(/* webpackChunkName: 'CmsProduct' */ './products/views/cmsProduct/CmsProduct.js')),
        "name": "products.CmsProduct"
    },
    "products.CmsProductView": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductView' */ './products/views/cmsProduct/CmsProductView.js')),
        "name": "products.CmsProductView"
    },
    "products.AfterCmsProductImageAndInfo": {
        "element": lazy(() => import(/* webpackChunkName: 'AfterCmsProductImageAndInfo' */ './products/views/cmsProduct/elements/AfterCmsProductImageAndInfo.js')),
        "name": "products.AfterCmsProductImageAndInfo"
    },
    "products.AfterCmsProductInfo": {
        "element": lazy(() => import(/* webpackChunkName: 'AfterCmsProductInfo' */ './products/views/cmsProduct/elements/AfterCmsProductInfo.js')),
        "name": "products.AfterCmsProductInfo"
    },
    "products.CmsProductConnected": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductConnected' */ './products/views/cmsProduct/elements/CmsProductConnected.js')),
        "name": "products.CmsProductConnected"
    },
    "products.CmsProductDescriptions": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductDescriptions' */ './products/views/cmsProduct/elements/CmsProductDescriptions.js')),
        "name": "products.CmsProductDescriptions"
    },
    "products.CmsProductDocuments": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductDocuments' */ './products/views/cmsProduct/elements/CmsProductDocuments.js')),
        "name": "products.CmsProductDocuments"
    },
    "products.CmsProductDrawers": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductDrawers' */ './products/views/cmsProduct/elements/CmsProductDrawers.js')),
        "name": "products.CmsProductDrawers"
    },
    "products.CmsProductImage": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductImage' */ './products/views/cmsProduct/elements/CmsProductImage.js')),
        "name": "products.CmsProductImage"
    },
    "products.CmsProductImages": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductImages' */ './products/views/cmsProduct/elements/CmsProductImages.js')),
        "name": "products.CmsProductImages"
    },
    "products.CmsProductInfo": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductInfo' */ './products/views/cmsProduct/elements/CmsProductInfo.js')),
        "name": "products.CmsProductInfo"
    },
    "products.CmsProductRecommended": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductRecommended' */ './products/views/cmsProduct/elements/CmsProductRecommended.js')),
        "name": "products.CmsProductRecommended"
    },
    "products.CmsProductSEO": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductSEO' */ './products/views/cmsProduct/elements/CmsProductSEO.js')),
        "name": "products.CmsProductSEO"
    },
    "products.CmsProductSpecifications": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductSpecifications' */ './products/views/cmsProduct/elements/CmsProductSpecifications.js')),
        "name": "products.CmsProductSpecifications"
    },
    "products.CmsProductYoutube": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductYoutube' */ './products/views/cmsProduct/elements/CmsProductYoutube.js')),
        "name": "products.CmsProductYoutube"
    },
    "products.OpacityDropDown": {
        "element": lazy(() => import(/* webpackChunkName: 'OpacityDropDown' */ './products/views/cmsProduct/elements/OpacityDropDown.js')),
        "name": "products.OpacityDropDown"
    },
    "products.AddProductForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddProductForm' */ './products/views/cmsProducts/AddProductForm.js')),
        "name": "products.AddProductForm"
    },
    "products.CmsProductItem": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductItem' */ './products/views/cmsProducts/CmsProductItem.js')),
        "name": "products.CmsProductItem"
    },
    "products.CmsProductPrice": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductPrice' */ './products/views/cmsProducts/CmsProductPrice.js')),
        "name": "products.CmsProductPrice"
    },
    "products.CmsProducts": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsProducts",
        "title": "proizvodi",
        "cmsMenu": {"position": "10"},
        "element": lazy(() => import(/* webpackChunkName: 'CmsProducts' */ './products/views/cmsProducts/CmsProducts.js')),
        "name": "products.CmsProducts"
    },
    "products.Product": {
        "layout": "public.PublicLayout",
        "path": "route.product",
        "optionalPaths": "route.productOptionalPaths",
        "element": lazy(() => import(/* webpackChunkName: 'Product' */ './products/views/product/Product.js')),
        "name": "products.Product"
    },
    "products.ProductLoader": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductLoader' */ './products/views/product/ProductLoader.js')),
        "name": "products.ProductLoader"
    },
    "products.ProductView": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductView' */ './products/views/product/ProductView.js')),
        "name": "products.ProductView"
    },
    "products.AfterProductInfo": {
        "element": lazy(() => import(/* webpackChunkName: 'AfterProductInfo' */ './products/views/product/elements/AfterProductInfo.js')),
        "name": "products.AfterProductInfo"
    },
    "products.LastViewed": {
        "element": lazy(() => import(/* webpackChunkName: 'LastViewed' */ './products/views/product/elements/LastViewed.js')),
        "name": "products.LastViewed"
    },
    "products.ProductBreadcrumbs": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductBreadcrumbs' */ './products/views/product/elements/ProductBreadcrumbs.js')),
        "name": "products.ProductBreadcrumbs"
    },
    "products.ProductConnected": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductConnected' */ './products/views/product/elements/ProductConnected.js')),
        "name": "products.ProductConnected"
    },
    "products.ProductGallery": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductGallery' */ './products/views/product/elements/ProductGallery.js')),
        "name": "products.ProductGallery"
    },
    "products.ProductInfo": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductInfo' */ './products/views/product/elements/ProductInfo.js')),
        "name": "products.ProductInfo"
    },
    "products.ProductPrice": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductPrice' */ './products/views/product/elements/ProductPrice.js')),
        "name": "products.ProductPrice"
    },
    "products.ProductRecommended": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductRecommended' */ './products/views/product/elements/ProductRecommended.js')),
        "name": "products.ProductRecommended"
    },
    "products.ProductSocials": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductSocials' */ './products/views/product/elements/ProductSocials.js')),
        "name": "products.ProductSocials"
    },
    "products.ProductTabs": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductTabs' */ './products/views/product/elements/ProductTabs.js')),
        "name": "products.ProductTabs"
    },
    "products.ProductWidgets": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductWidgets' */ './products/views/product/elements/ProductWidgets.js')),
        "name": "products.ProductWidgets"
    },
    "products.ProductYoutube": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductYoutube' */ './products/views/product/elements/ProductYoutube.js')),
        "name": "products.ProductYoutube"
    },
    "products.RegisterViewing": {
        "element": lazy(() => import(/* webpackChunkName: 'RegisterViewing' */ './products/views/product/elements/RegisterViewing.js')),
        "name": "products.RegisterViewing"
    },
    "products.Shop": {
        "layout": "public.PublicLayout",
        "path": "route.shop",
        "optionalPaths": "route.shopOptionalPaths",
        "element": lazy(() => import(/* webpackChunkName: 'Shop' */ './products/views/shop/Shop.js')),
        "name": "products.Shop"
    },
    "products.ShopLoader": {
        "element": lazy(() => import(/* webpackChunkName: 'ShopLoader' */ './products/views/shop/ShopLoader.js')),
        "name": "products.ShopLoader"
    },
    "products.ShopProducts": {
        "element": lazy(() => import(/* webpackChunkName: 'ShopProducts' */ './products/views/shop/ShopProducts.js')),
        "name": "products.ShopProducts"
    },
    "products.CmsProductDrawer": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductDrawer' */ './products/widgets/CmsProductDrawer.js')),
        "name": "products.CmsProductDrawer"
    },
    "products.CmsProductsDrawer": {
        "element": lazy(() => import(/* webpackChunkName: 'CmsProductsDrawer' */ './products/widgets/CmsProductsDrawer.js')),
        "name": "products.CmsProductsDrawer"
    },
    "products.LoadProductsCarousel": {
        "element": lazy(() => import(/* webpackChunkName: 'LoadProductsCarousel' */ './products/widgets/LoadProductsCarousel.js')),
        "name": "products.LoadProductsCarousel"
    },
    "products.ProductRender": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductRender' */ './products/widgets/ProductRender.js')),
        "name": "products.ProductRender"
    },
    "products.ProductsCarousel": {
        "element": lazy(() => import(/* webpackChunkName: 'ProductsCarousel' */ './products/widgets/ProductsCarousel.js')),
        "name": "products.ProductsCarousel"
    },
    "products.useQueryParser": {"element": useQueryParser, "name": "products.useQueryParser"},
    "public.Contact": {
        "layout": "public.PublicLayout",
        "path": "route.contact",
        "element": lazy(() => import(/* webpackChunkName: 'Contact' */ './public/views/contact/Contact.js')),
        "name": "public.Contact"
    },
    "public.ContactForm": {
        "element": lazy(() => import(/* webpackChunkName: 'ContactForm' */ './public/views/contact/ContactForm.js')),
        "name": "public.ContactForm"
    },
    "public.ContactInfo": {
        "layout": "public.PublicLayout",
        "path": "route.contact",
        "element": lazy(() => import(/* webpackChunkName: 'ContactInfo' */ './public/views/contact/ContactInfo.js')),
        "name": "public.ContactInfo"
    },
    "public.ContactMap": {
        "element": lazy(() => import(/* webpackChunkName: 'ContactMap' */ './public/views/contact/ContactMap.js')),
        "name": "public.ContactMap"
    },
    "public.Error404": {
        "layout": "public.PublicLayout",
        "path": "route.catchAll",
        "element": lazy(() => import(/* webpackChunkName: 'Error404' */ './public/views/error404/Error404.js')),
        "name": "public.Error404"
    },
    "public.CookiesAlert": {
        "element": lazy(() => import(/* webpackChunkName: 'CookiesAlert' */ './public/views/layout/CookiesAlert.js')),
        "name": "public.CookiesAlert"
    },
    "public.DarkModeSwitcher": {
        "element": lazy(() => import(/* webpackChunkName: 'DarkModeSwitcher' */ './public/views/layout/DarkModeSwitcher.js')),
        "name": "public.DarkModeSwitcher"
    },
    "public.HeaderMenu": {
        "element": lazy(() => import(/* webpackChunkName: 'HeaderMenu' */ './public/views/layout/HeaderMenu.js')),
        "name": "public.HeaderMenu"
    },
    "public.MobileBottomDrawer": {
        "element": lazy(() => import(/* webpackChunkName: 'MobileBottomDrawer' */ './public/views/layout/MobileBottomDrawer.js')),
        "name": "public.MobileBottomDrawer"
    },
    "public.PublicFooter": {
        "element": lazy(() => import(/* webpackChunkName: 'PublicFooter' */ './public/views/layout/PublicFooter.js')),
        "name": "public.PublicFooter"
    },
    "public.PublicLayout": {
        "path": "route.publicLayout",
        "element": lazy(() => import(/* webpackChunkName: 'PublicLayout' */ './public/views/layout/PublicLayout.js')),
        "name": "public.PublicLayout"
    },
    "public.PublicSearch": {
        "element": lazy(() => import(/* webpackChunkName: 'PublicSearch' */ './public/views/layout/PublicSearch.js')),
        "name": "public.PublicSearch"
    },
    "public.ToTop": {
        "element": lazy(() => import(/* webpackChunkName: 'ToTop' */ './public/views/layout/ToTop.js')),
        "name": "public.ToTop"
    },
    "public.Breadcrumbs": {
        "loader": <div className='container mx-auto'>
            <div className='h-4 mt-8 md:w-1/3 bg-gray-300 opacity-10 animate-pulse mb-5 md:mb-8'/>
        </div>,
        "element": lazy(() => import(/* webpackChunkName: 'Breadcrumbs' */ './public/widgets/Breadcrumbs.js')),
        "name": "public.Breadcrumbs"
    },
    "public.ImageZoom": {
        "element": lazy(() => import(/* webpackChunkName: 'ImageZoom' */ './public/widgets/ImageZoom.js')),
        "name": "public.ImageZoom"
    },
    "public.PopupImage": {
        "element": lazy(() => import(/* webpackChunkName: 'PopupImage' */ './public/widgets/PopupImage.js')),
        "name": "public.PopupImage"
    },
    "public.Title": {
        "element": lazy(() => import(/* webpackChunkName: 'Title' */ './public/widgets/Title.js')),
        "name": "public.Title"
    },
    "pwa.InstallerButton": {
        "element": lazy(() => import(/* webpackChunkName: 'InstallerButton' */ './pwa/widgets/InstallerButton.js')),
        "name": "pwa.InstallerButton"
    },
    "seo.RouteSeoMiddleware": {"type": "middleware", "element": RouteSeoMiddleware, "name": "seo.RouteSeoMiddleware"},
    "seo.seoAll": {"element": seoAll, "name": "seo.seoAll"},
    "settings.DropDown": {
        "element": lazy(() => import(/* webpackChunkName: 'DropDown' */ './settings/views/settings/DropDown.js')),
        "name": "settings.DropDown"
    },
    "settings.Settings": {
        "layout": "cms.CmsLayout",
        "path": "route.settings",
        "cmsMenu": {"position": "60"},
        "title": "podesavanja",
        "element": lazy(() => import(/* webpackChunkName: 'Settings' */ './settings/views/settings/Settings.js')),
        "name": "settings.Settings"
    },
    "settings.AfterContactInfo": {
        "element": lazy(() => import(/* webpackChunkName: 'AfterContactInfo' */ './settings/views/settings/elements/AfterContactInfo.js')),
        "name": "settings.AfterContactInfo"
    },
    "settings.UpdateField": {
        "element": lazy(() => import(/* webpackChunkName: 'UpdateField' */ './settings/widgets/UpdateField.js')),
        "name": "settings.UpdateField"
    },
    "settings.LoadSettingsWrapper": {
        "type": "wrapper",
        "dependency": "lang.MatchLangWrapper",
        "element": LoadSettingsWrapper,
        "name": "settings.LoadSettingsWrapper"
    },
    "storage.Storage": {
        "layout": "cms.CmsLayout",
        "path": "route.storage",
        "cmsMenu": {"position": "70"},
        "title": "fajlovi",
        "element": lazy(() => import(/* webpackChunkName: 'Storage' */ './storage/views/storage/Storage.js')),
        "name": "storage.Storage"
    },
    "storage.StorageItem": {
        "element": lazy(() => import(/* webpackChunkName: 'StorageItem' */ './storage/views/storage/StorageItem.js')),
        "name": "storage.StorageItem"
    },
    "storage.AddFileForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddFileForm' */ './storage/views/uploader/AddFileForm.js')),
        "name": "storage.AddFileForm"
    },
    "storage.Uploader": {
        "element": lazy(() => import(/* webpackChunkName: 'Uploader' */ './storage/views/uploader/Uploader.js')),
        "name": "storage.Uploader"
    },
    "storage.StorageDrawer": {
        "element": lazy(() => import(/* webpackChunkName: 'StorageDrawer' */ './storage/widgets/StorageDrawer.js')),
        "name": "storage.StorageDrawer"
    },
    "storage.UpdateImage": {
        "element": lazy(() => import(/* webpackChunkName: 'UpdateImage' */ './storage/widgets/UpdateImage.js')),
        "name": "storage.UpdateImage"
    },
    "storage.image": {"element": image, "name": "storage.image"},
    "storage.link": {"element": link, "name": "storage.link"},
    "textEditor.BlockStyleControls": {
        "element": lazy(() => import(/* webpackChunkName: 'BlockStyleControls' */ './textEditor/BlockStyleControls.js')),
        "name": "textEditor.BlockStyleControls"
    },
    "textEditor.ErrorBoundary": {
        "element": lazy(() => import(/* webpackChunkName: 'ErrorBoundary' */ './textEditor/ErrorBoundary.js')),
        "name": "textEditor.ErrorBoundary"
    },
    "textEditor.InlineStyleControls": {
        "element": lazy(() => import(/* webpackChunkName: 'InlineStyleControls' */ './textEditor/InlineStyleControls.js')),
        "name": "textEditor.InlineStyleControls"
    },
    "textEditor.RichTextEditor": {
        "element": lazy(() => import(/* webpackChunkName: 'RichTextEditor' */ './textEditor/RichTextEditor.js')),
        "name": "textEditor.RichTextEditor"
    },
    "textEditor.StyleButton": {
        "element": lazy(() => import(/* webpackChunkName: 'StyleButton' */ './textEditor/StyleButton.js')),
        "name": "textEditor.StyleButton"
    },
    "users.CmsUsers": {
        "layout": "cms.CmsLayout",
        "path": "route.cmsUsers",
        "cmsMenu": {"position": "40"},
        "title": "korisnici",
        "element": lazy(() => import(/* webpackChunkName: 'CmsUsers' */ './users/views/cmsUsers/CmsUsers.js')),
        "name": "users.CmsUsers"
    },
    "users.UserItem": {
        "element": lazy(() => import(/* webpackChunkName: 'UserItem' */ './users/views/cmsUsers/UserItem.js')),
        "name": "users.UserItem"
    },
    "users.Dashboard": {
        "layout": "users.UserLayout",
        "path": "route.userDashboard",
        "element": lazy(() => import(/* webpackChunkName: 'Dashboard' */ './users/views/dashboard/Dashboard.js')),
        "name": "users.Dashboard"
    },
    "users.Email": {
        "layout": "users.UserAuthLayout",
        "path": "route.userEmail",
        "element": lazy(() => import(/* webpackChunkName: 'Email' */ './users/views/email/Email.js')),
        "name": "users.Email"
    },
    "users.UserAuthLayout": {
        "layout": "public.PublicLayout",
        "path": "route.userAuthLayout",
        "element": lazy(() => import(/* webpackChunkName: 'UserAuthLayout' */ './users/views/layout/UserAuthLayout.js')),
        "name": "users.UserAuthLayout"
    },
    "users.UserLayout": {
        "layout": "public.PublicLayout",
        "path": "route.userLayout",
        "element": lazy(() => import(/* webpackChunkName: 'UserLayout' */ './users/views/layout/UserLayout.js')),
        "name": "users.UserLayout"
    },
    "users.Login": {
        "layout": "users.UserAuthLayout",
        "path": "route.userLogin",
        "element": lazy(() => import(/* webpackChunkName: 'Login' */ './users/views/login/Login.js')),
        "name": "users.Login"
    },
    "users.Register": {
        "layout": "users.UserAuthLayout",
        "path": "route.userRegister",
        "element": lazy(() => import(/* webpackChunkName: 'Register' */ './users/views/register/Register.js')),
        "name": "users.Register"
    },
    "users.Reset": {
        "layout": "users.UserAuthLayout",
        "path": "route.userReset",
        "element": lazy(() => import(/* webpackChunkName: 'Reset' */ './users/views/reset/Reset.js')),
        "name": "users.Reset"
    },
    "users.ResetForm": {
        "element": lazy(() => import(/* webpackChunkName: 'ResetForm' */ './users/views/reset/ResetForm.js')),
        "name": "users.ResetForm"
    },
    "users.Guard": {
        "element": lazy(() => import(/* webpackChunkName: 'Guard' */ './users/widgets/Guard.js')),
        "name": "users.Guard"
    },
    "users.LoginButton": {
        "element": lazy(() => import(/* webpackChunkName: 'LoginButton' */ './users/widgets/LoginButton.js')),
        "name": "users.LoginButton"
    },
    "wishlist.Wishlist": {
        "layout": "public.PublicLayout",
        "path": "route.wishlist",
        "element": lazy(() => import(/* webpackChunkName: 'Wishlist' */ './wishlist/views/wishlist/Wishlist.js')),
        "name": "wishlist.Wishlist"
    },
    "wishlist.WishlistButton": {
        "element": lazy(() => import(/* webpackChunkName: 'WishlistButton' */ './wishlist/widgets/WishlistButton.js')),
        "name": "wishlist.WishlistButton"
    },
    "wishlist.WishlistProductButton": {
        "element": lazy(() => import(/* webpackChunkName: 'WishlistProductButton' */ './wishlist/widgets/WishlistProductButton.js')),
        "name": "wishlist.WishlistProductButton"
    },
    "wishlist.PreloadWishlistWrapper": {
        "type": "wrapper",
        "element": PreloadWishlistWrapper,
        "name": "wishlist.PreloadWishlistWrapper"
    },
    "settings.AddContactForm": {
        "element": lazy(() => import(/* webpackChunkName: 'AddContactForm' */ './settings/views/settings/elements/AddContactForm')),
        "name": "settings.AddContactForm"
    }
}