import {gql} from "@apollo/client";
import {StorageFragment} from "./Storage";
import {StorageFragmentAdmin} from "./Storage";
import {CategoryFragment} from "./Category";
import {CategoryFragmentAdmin} from "./Category";
import {PAGINATOR_INFO_FRAGMENT} from "../fragments/PaginatorInfoFragment";
import {FilterFragment} from "./Filter";
import {FilterFragmentAdmin} from "./Filter";
import {ProductSpecificationFragment} from "./ProductSpecification";
import {ProductSpecificationFragmentAdmin} from "./ProductSpecification";
import {ProductDocumentFragment} from "./ProductDocument";
import {ProductDocumentFragmentAdmin} from "./ProductDocument";

export const ProductFragment = gql`
	${StorageFragment}
	${CategoryFragment}
    fragment ProductFragment on Product {
		id
		active
		sold
		connected_id
		name
		code
		brand
		price
		discount_price
		process_price
		discount_price_start
		discount_price_end
		action
		new
		popular
		description
		long_description
		video
		seo_title
		seo_keywords
		seo_description
		image 	 { 
			...StorageFragment 
		}
		categories 	 { 
			...CategoryFragment 
		}
    }
`

export const ProductFragmentAdmin = gql`
	${StorageFragmentAdmin}
	${CategoryFragmentAdmin}
    fragment ProductFragmentAdmin on Product {
		id
		active
		sold
		connected_id
		name
		names
		code
		brand
		price
		prices
		discount_price
		discount_prices
		process_price
		discount_price_start
		discount_price_end
		action
		new
		popular
		description
		descriptions
		long_description
		long_descriptions
		video
		seo_title
		seo_keywords
		seo_description
		seo_titles
		seo_keywords_all
		seo_descriptions
		image 	 { 
			...StorageFragmentAdmin 
		}
		categories 	 { 
			...CategoryFragmentAdmin 
		}
		views_count
		wishlist_added
    }
`

export const GetProducts = gql`
	${ProductFragment}
	${PAGINATOR_INFO_FRAGMENT}
     query ($first: Int, $page: Int, $ids: [ID], $id: ID, $search: String, $categories: [ID], $group: ID, $campaign: String, $brands: [String], $filters: String, $order: String, $orderByIds: [ID], $status: String, $start_date: String, $end_date: String) {
         GetProducts (first: $first, page: $page, ids: $ids, id: $id, search: $search, categories: $categories, group: $group, campaign: $campaign, brands: $brands, filters: $filters, order: $order, orderByIds: $orderByIds, status: $status, start_date: $start_date, end_date: $end_date) {
			data {
			...ProductFragment
			}
			paginatorInfo {
				...PaginatorInfoFragment
			}
         }
    }
`

export const GetProductsAdmin = gql`
	${ProductFragmentAdmin}
	${PAGINATOR_INFO_FRAGMENT}
     query ($first: Int, $page: Int, $ids: [ID], $id: ID, $search: String, $categories: [ID], $group: ID, $campaign: String, $brands: [String], $filters: String, $order: String, $orderByIds: [ID], $status: String, $start_date: String, $end_date: String) {
         GetProducts (first: $first, page: $page, ids: $ids, id: $id, search: $search, categories: $categories, group: $group, campaign: $campaign, brands: $brands, filters: $filters, order: $order, orderByIds: $orderByIds, status: $status, start_date: $start_date, end_date: $end_date) {
			data {
			...ProductFragmentAdmin
			}
			paginatorInfo {
				...PaginatorInfoFragment
			}
         }
    }
`

export const GetProductsTotal = gql`
     query ($group: ID) {
         GetProductsTotal (group: $group) 
    }
`

export const GetProductsTotalAdmin = gql`
     query ($group: ID) {
         GetProductsTotal (group: $group) 
    }
`

export const GetProduct = gql`
	${ProductFragment}
	${StorageFragment}
	${FilterFragment}
	${ProductSpecificationFragment}
	${ProductDocumentFragment}
     query ($id: ID!) {
         GetProduct (id: $id) {
			...ProductFragment
			images 	 { 
				...StorageFragment 
			}
			filters 	 { 
				...FilterFragment 
			}
			specifications 	 { 
				...ProductSpecificationFragment 
			}
			connected 	 { 
				...ProductFragment 
			}
			documents 	 { 
				...ProductDocumentFragment 
			}
         }
    }
`

export const GetProductAdmin = gql`
	${ProductFragmentAdmin}
	${StorageFragmentAdmin}
	${FilterFragmentAdmin}
	${ProductSpecificationFragmentAdmin}
	${ProductDocumentFragmentAdmin}
     query ($id: ID!) {
         GetProduct (id: $id) {
			...ProductFragmentAdmin
			images 	 { 
				...StorageFragmentAdmin 
			}
			filters 	 { 
				...FilterFragmentAdmin 
			}
			specifications 	 { 
				...ProductSpecificationFragmentAdmin 
			}
			connected 	 { 
				...ProductFragmentAdmin 
			}
			documents 	 { 
				...ProductDocumentFragmentAdmin 
			}
         }
    }
`

export const CreateProduct = gql`
	${ProductFragment}
     mutation ($name: String!) {
         CreateProduct (name: $name) {
			...ProductFragment
         }
    }
`

export const UpdateProduct = gql`
	${ProductFragment}
     mutation ($id: ID!, $position: Int, $active: Boolean, $sold: Boolean, $name: String, $code: String, $image_id: ID, $brand: String, $description: String, $long_description: String, $action: Boolean, $new: Boolean, $popular: Boolean, $price: Float, $discount_price: Float, $discount_price_start: String, $discount_price_end: String, $seo_title: String, $seo_keywords: String, $seo_description: String, $video: String, $connected_id: String, $attach_category: ID, $detach_category: ID, $attach_filter: ID, $detach_filter: ID, $attach_images: [ID], $detach_image: ID, $order_images: [ID], $attach_documents: [ID], $detach_document: ID, $attach_specification: Boolean, $detach_specification: ID) {
         UpdateProduct (id: $id, position: $position, active: $active, sold: $sold, name: $name, code: $code, image_id: $image_id, brand: $brand, description: $description, long_description: $long_description, action: $action, new: $new, popular: $popular, price: $price, discount_price: $discount_price, discount_price_start: $discount_price_start, discount_price_end: $discount_price_end, seo_title: $seo_title, seo_keywords: $seo_keywords, seo_description: $seo_description, video: $video, connected_id: $connected_id, attach_category: $attach_category, detach_category: $detach_category, attach_filter: $attach_filter, detach_filter: $detach_filter, attach_images: $attach_images, detach_image: $detach_image, order_images: $order_images, attach_documents: $attach_documents, detach_document: $detach_document, attach_specification: $attach_specification, detach_specification: $detach_specification) {
			...ProductFragment
         }
    }
`

export const DeleteProduct = gql`
	${ProductFragment}
     mutation ($id: ID!) {
         DeleteProduct (id: $id) {
			...ProductFragment
         }
    }
`

